$white: #fff;
$black: #000000;
$light-dark: #00000052;
$light-grey: #dddddd;
$grey: #6F6F6F;
$blue: #48A6FE;

$c-text: #6F6F6F;

$gradient-linear: linear-gradient(to right, #FFA217, #F4598F, #48A7FF);

$t-slow: all 0.6s cubic-bezier(0.33, 1, 0.68, 1);
$t-fast: all 0.3s cubic-bezier(0.33, 1, 0.68, 1);
$t-superfast: all 0.1s cubic-bezier(0.33, 1, 0.68, 1);
$t-tfast: transform 0.3s cubic-bezier(0.33, 1, 0.68, 1);
$t-tslow: transform 0.6s cubic-bezier(0.33, 1, 0.68, 1);


// border-radius
$round: 1000px;
$rounded: 3px;
$rounded2: 10px;

// shadows
$shadow: 0px 30px 77px #00000033;
$light-shadow: 0px 0px 25px -15px #0000001a;

//z-index
$top: 1000;
$between: 500;
$behind: -100;

// layout - to complete
$desktop-gap: 15vw;
$tablet-gap: 5vw;
$mobile-gap: 2rem;

$site-width: 1200px;
$header-height: 50px;