@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Montserrat:wght@400;500;600;700;800;900&display=swap');

$size-text: 16px;
$size-text-m: 15px;
$line-height: 1.5;

$h4: $size-text * $line-height;
$h3: $h4 * $line-height;
$h2: $h3 * $line-height;
$h1: $h2 * $line-height;

$h4-m: $size-text-m * $line-height;
$h3-m: $h4-m * $line-height;
$h2-m: $h3-m * $line-height;
$h1-m: $h2-m * $line-height;

body {
  font-size: $size-text;
  line-height: $line-height;
  font-family: 'Montserrat', sans-serif;
  color: $c-text;
  font-weight: 500;
}

h1, h2, h3, h4 {
  font-family: Bebas Neue;
  font-style: normal;
  font-weight: normal;
}

h1 {
  font-size: $h1 * 2;
}

h2 {
  font-size: $h2;
  text-align: center;

  i {
    font-size: calc(#{$h2} / 1.25)!important;
  }
}

h3 {
  font-size: $h3;
}

h4 {
  font-size: $h4;
}

p {
  margin-block-start: 1.5rem;
  margin-block-end: 1.5rem;
}

a {
  font-weight: 600;
}

label {
  font-weight: 700;
}

@media (max-width: 500px) {
  h1 {font-size: $h1-m;}
  h2 {font-size: $h2-m;}
  h3 {font-size: $h3-m;}
  h4 {font-size: $h4-m;}
  p {font-size: $size-text-m;}
}