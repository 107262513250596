@media(max-width: 1460px) {
    .hero-content-div {
        flex-direction: column;
        align-items: flex-start;
        width: 500px;
        .btn {
            margin-left: 0;
            margin-top: 1.5rem;
        }
    }

    .hero {
        padding-bottom: 10rem;
        padding-top: 10rem;

        &-content-title {
            line-height: 0.5;
            margin: 5rem 0;
            width: 30vw;
        }

        &-me {
            position: absolute;
            right: 0px;
            z-index: $behind;
            height: 800px;
            width: auto;
            bottom: 0;
        }
    }

    .portfolio-el {
        width: 80%;
    }

    .portfolio-buttons {
        width: calc(80% + 8rem);
        margin-left: calc((100vw - 80%) / 2 - 4.5rem);
    }

    .wrapper {
        width: 100%;
    }
}

@media(max-width: 1160px) {
    .hero-bg-picto {
        display: none;
    }

    .header {
        box-sizing: border-box;
        padding: 0.75rem 1.5rem;
    }

    .hero {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}


// desktop
@media(max-width: 900px) {
    .desktop {
        display: none!important;
    }

    .mobile {
        display: flex!important;
    }

    .btn-menu {
        height: 100%;
        width: $h4;
        
        i {position: absolute; font-size: $h4;}
        .fa-bars {transition: $t-fast; opacity: 1;}
        .fa-times {transition: $t-fast; opacity: 0;}
    }

    .header {
        padding-top: 1.2rem;
        &.scrolled {
            .menu-el:nth-child(3) {
                border: none;
            }
        }
    }

    .menu {
        position: fixed;
        box-sizing: border-box;
        top: 5rem;
        left: 2rem;
        width: calc(100% - 4rem);
        box-shadow: $shadow;
        border-radius: $rounded;
        background-color: $white;
        color: $c-text!important;
        z-index: calc(#{$behind} - 5);
        transform: translateY(50px) rotateX(-90deg);
        transition: $t-fast;
        opacity: 0;
        
        @include flex-column-center-y;

        &-el {
            margin: 0!important;
            text-align: center;
            padding: 1rem;
        }
    }

    body.menu--open {
        .btn-menu {
            .fa-bars {opacity: 0;}
            .fa-times {opacity: 1;}
        }

        .menu {
            opacity: 1;
            transform: translateY(0) rotateX(0);
        }
    }

    .hero {
        height: 75vh;
        padding-top: 5rem;
        &-content {
            display: flex;
            align-items: center;
            width: 100%;
            &-div {
                position: absolute;
                padding: 1.5rem;
                justify-self: flex-end;
                text-align: center;
                align-items: center;
                border-radius: $rounded2;
    
                width: auto;
                bottom: 0px;
                padding-bottom: 3rem;
            }

            &-title {
                width: 100%;
                margin-top: 5rem;
                font-size: 120px;
                &Second {
                    font-size: $h3;
                }
            }
        }

        &-card {
            &1 {
                right: inherit;
                left: 2rem;
                bottom: calc(30vh + 9rem);
            }

            &2 {
                right: inherit;
                left: 5rem;
                bottom: 30vh;
            }
        }

        &-me {
            height: 75vh;
        }
    }

    .portfolio {
        padding-bottom: 100px;
        height: 800px;
        &-el {
            flex-direction: column-reverse;
            height: auto;
            &-div {
                width: 100%;
                p {
                    margin-bottom: 1.5rem;
                }
                .btn {
                    position: relative;
                    display: inline-flex;
                    transform: translateY(3rem);
                    align-self: flex-start;
                }
            }
            
            &-image {
                width: 100%;
                height: 300px;
                &-img {
                    margin-top: -10%;
                }
            }
        }

        &-indicator {
            margin-top: -5rem;
        }

        &-buttons {
            box-sizing: border-box;
            margin: 0;
            margin-left: 10%;
            margin-right: 10%;
            width: 80%;
            margin-top: 805px;
        }
    }

    .timeline {
        flex-direction: column;
        width: 70%;
        margin-left: 10%;
        margin: 5rem 0;

        &-el {
            margin-bottom: 7rem;
            &:last-child {
                margin-bottom: 0;
            }
            
            &:nth-child(1) {
                &::after {
                    margin-left: -2rem;
                }
            }

            &:nth-child(3) {
                margin-bottom: 6rem;
                &::after {
                    height: calc(100% + 6.2rem);
                }
            }
            
            &:nth-child(4) {
                height: 120px;
                width: 120px;
            }

            &:after {
                width: 1px;
                height: calc(100% + 8.25rem);
                top: 0;
                margin: 0;
                margin-left: -2rem;
                margin-top: -3.4rem;
                border: none;
                border-left: 2px solid $light-grey;
            }


            &-date {
                left: 0.1rem;
                margin-top: -4rem;
                top: 0;
                display: block;
                &::before {
                    width: 0.5rem;
                    height: 1px;
                    margin-top: 0.6rem;
                    border-left: none;
                    border-top: 2px solid $light-grey;
                    top: inherit;
                    left: -1.1rem;
                }
            }

            &-btn {
                &-date {
                    margin-top: inherit;
                }
            } 
        }
    }

    .about-text {
        flex-direction: column;
        margin: 0 1.5rem;
        margin-bottom: 3rem;
        margin-top: 0;
        & p {
            width: inherit;
        }
    }

    #about .wrapper {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
    }

    .pretty-bg {
        &:after {
            z-index: $behind;
            mask-image: -webkit-gradient(linear, left center, right top, from(rgba(0, 0, 0, 0.06)), to(rgba(0,0,0,0.01)));
            -webkit-mask-image: -webkit-gradient(linear, left center, right top, from(rgba(0, 0, 0, 0.06)), to(rgba(0,0,0,0.01)));
        }
    }

    .contact {
        &-div {
            box-sizing: border-box;
            margin: 2rem;
            width: calc(100% - 4rem);
        }
    }
}

@media(max-width: 700px) {
    .hero-me {
        right: -100px;
    }

    .form {
        &-row {
            margin-bottom: 1rem;
            flex-direction: column;
            .form-field {
                margin-bottom: 1.5rem;
                &:last-child {margin-bottom: inherit;}
            }
        }
    }

}

@media(max-width: 500px) {
    .hero {
        overflow-y: visible;
        height: 690px;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        &-content-title {
            font-size: 20vw;
            line-height: 12.5vw;
            margin-top: 2rem;
            margin-bottom: 2rem;

            text-shadow: $shadow;
            &Second {
                font-size: 6vw;
            }
        }

        &-card {
            top: inherit;
            bottom: inherit;
            left: inherit;
            right: inherit;
            box-sizing: border-box;
            width: 300px;
            max-width: 100%;
            padding: 1rem;
            margin: 1rem 0;

            &1 {bottom: 12rem; align-self: flex-end;}
            &2 {bottom: 20rem; align-self: flex-start;}

            &-svg {
                padding: 0 1rem;
            }
        }

        &-me {
            height: 600px;
            right: inherit;
            margin-bottom: 100px;
        }

        &-transition {
            height: 50px;
        }

        &-bg {
            background-image: radial-gradient(circle at -20% 5%, #ffffff91 0%, transparent 30%),
                                radial-gradient(circle at 100% 50%, #ffffffa1 0%, transparent 25%),
                                radial-gradient(circle at 50% 90%, #ffffff86 0%, transparent 50%),
                                $gradient-linear;
        }
    }

    .timeline {
       width: 90%;
       margin-left: 10%;
    }

    .portfolio {
        margin: 0;
        width: 100%;
        &-el {
            width: 350px;
            max-width: 100%;
            &-div {
                padding: 2rem;
            }
        }
    }

    .contact {
        overflow-y: scroll;
        .wrapper {
            height: calc(100% + 2rem);
            box-sizing: border-box;
            padding: 2rem 0;
        }
        &-div {
            width: calc(100vw - 2rem);
            margin-top: 30rem;
            margin: 0 1rem;
        }

        .form {
            padding: 1.5rem;
            padding-bottom: 0;
            &-header {
                padding: 1.5rem;
                margin-bottom: 0;

                h2 {
                    margin-bottom: -0.5rem;
                }
            }
        }
            
        &-info {
            flex-direction: column;
            &-el {
                justify-content: center;
                align-items: center;
                margin-bottom: 1.5rem;
                margin-left: 4rem;
                margin-right: 4rem!important;
                &:last-child {
                    margin-bottom: 0;
                }

                .btn {
                    padding-top: 0.5rem;
                    padding-bottom: 0.5rem;
                }
            }
        }
    }
}