@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Montserrat:wght@400;500;600;700;800;900&display=swap");
body {
  margin: 0;
}

img {
  display: block;
}

a {
  color: inherit;
  text-decoration: none;
}

button, input, textarea {
  background-color: transparent;
  font-size: inherit;
  color: inherit;
  font-weight: inherit;
  border: none;
  font-family: inherit;
}
button:focus, input:focus, textarea:focus {
  outline: none;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

body {
  font-size: 16px;
  line-height: 1.5;
  font-family: "Montserrat", sans-serif;
  color: #6F6F6F;
  font-weight: 500;
}

h1, h2, h3, h4 {
  font-family: Bebas Neue;
  font-style: normal;
  font-weight: normal;
}

h1 {
  font-size: 162px;
}

h2 {
  font-size: 54px;
  text-align: center;
}
h2 i {
  font-size: calc(54px / 1.25) !important;
}

h3 {
  font-size: 36px;
}

h4 {
  font-size: 24px;
}

p {
  margin-block-start: 1.5rem;
  margin-block-end: 1.5rem;
}

a {
  font-weight: 600;
}

label {
  font-weight: 700;
}

@media (max-width: 500px) {
  h1 {
    font-size: 75.9375px;
  }

  h2 {
    font-size: 50.625px;
  }

  h3 {
    font-size: 33.75px;
  }

  h4 {
    font-size: 22.5px;
  }

  p {
    font-size: 15px;
  }
}
@keyframes hideLeft {
  from {
    transform: translateX(0%) scale(1);
    opacity: 1;
  }
  to {
    transform: translateX(-110%) scale(0.6);
    opacity: 0;
  }
}
@keyframes hideRight {
  from {
    transform: translateX(0%) scale(1);
    opacity: 1;
  }
  to {
    transform: translateX(110%) scale(0.6);
    opacity: 0;
  }
}
@keyframes showLeft {
  from {
    transform: translateX(-110%) scale(0.6);
    opacity: 0;
  }
  to {
    transform: translateX(0%) scale(1);
    opacity: 1;
  }
}
@keyframes showRight {
  from {
    transform: translateX(110%) scale(0.6);
    opacity: 0;
  }
  to {
    transform: translateX(0%) scale(1);
    opacity: 1;
  }
}
@keyframes hover {
  0% {
    transform: scale(0.2);
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  100% {
    transform: scale(15);
    opacity: 0;
  }
}
@keyframes contactOpening {
  from {
    transform: translateY(90%) scale(0.6) rotateX(-90deg);
    opacity: 0;
  }
  to {
    transform: translateY(0%) scale(1) rotateX(0deg);
    opacity: 1;
  }
}
@keyframes contactClosing {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes float1 {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(30px);
  }
}
@keyframes float2 {
  from {
    transform: translateY(-10px);
  }
  to {
    transform: translateY(30px);
  }
}
@keyframes float3 {
  from {
    transform: translateY(30px);
  }
  to {
    transform: translateY(0px);
  }
}
@keyframes fadeInAndOut {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  10% {
    transform: translateY(0px);
    opacity: 1;
  }
  90% {
    transform: translateY(0px);
    opacity: 1;
  }
  100% {
    transform: translateY(100px);
    opacity: 0;
  }
}
.useSvg {
  display: none;
}

.mobile {
  display: none !important;
}

@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .header, .hero-content-div {
    backdrop-filter: blur(30px);
  }
}
/* slightly transparent fallback for Firefox (not supporting backdrop-filter) */
@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .header {
    background-color: #000000ce;
  }
}
html, body, section {
  overflow-x: hidden;
  scroll-behavior: smooth;
}

*::-webkit-scrollbar {
  width: 0px;
  /*&-track {
      background: #fff; 
  }

  &-thumb {
      background: #c7c7c7;
      border-radius: $round;
  }

  &-thumb:hover {
      background: #a3a3a3; 
  }*/
}

.wrapper {
  max-width: 1200px;
}

.pretty-bg {
  position: relative;
  overflow: hidden;
}
.pretty-bg:after {
  z-index: -100;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../assets/graph.svg");
  background-size: 3rem;
  color: #fff;
  mask-image: -webkit-gradient(linear, left bottom, right top, from(rgba(0, 0, 0, 0.06)), to(rgba(0, 0, 0, 0.01)));
  -webkit-mask-image: -webkit-gradient(linear, left bottom, right top, from(rgba(0, 0, 0, 0.06)), to(rgba(0, 0, 0, 0.01)));
}

.btn {
  padding: 0.7rem 1rem;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 3px;
  font-weight: 600;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.33, 1, 0.68, 1);
}
.btn-white {
  border: 1px solid #00000052;
}
.btn-picto {
  display: flex;
  align-items: center;
}
.btn-picto i {
  margin-right: 0.75rem;
}
.btn:active {
  transform: scale(0.95);
}
.btn .hover {
  position: absolute;
  border-radius: 1000px;
  background-color: rgba(0, 0, 0, 0.075);
  animation: hover 1.5s;
  width: 100px;
  height: 100px;
  margin-top: -50px;
  margin-left: -50px;
}

.header {
  display: flex;
  justify-content: center;
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  background-color: #00000052;
  box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.1);
  color: #fff;
  padding: 0.75rem 0;
  transition: all 0.6s cubic-bezier(0.33, 1, 0.68, 1);
}
.header .wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.header-logo {
  width: 24px;
  height: 24px;
}
.header .menu {
  display: flex;
  align-items: center;
}
.header .menu-el {
  margin-right: 3rem;
}
.header .menu-el:last-child {
  margin-right: 0;
}
.header.scrolled {
  background-color: #fff;
  color: #6F6F6F;
}
.header.scrolled .menu-el:last-child {
  border: 1px solid #00000052;
  border-radius: 3px;
}

.hero {
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
  padding: 12rem 0 5rem 0;
  color: #fff;
}
.hero .wrapper {
  display: flex;
  width: 100%;
}
.hero-content {
  display: flex;
  flex-direction: column;
}
.hero-content-title {
  line-height: calc(54px + 1.5rem);
}
.hero-content-titleSecond {
  font-size: 36px;
  font-family: "Montserrat", sans-serif;
}
.hero-content-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  padding-left: 1.5rem;
  background-color: #00000052;
  border-radius: 3px;
  box-shadow: 0px 10px 32px rgba(0, 0, 0, 0.1);
}
.hero-content-div p {
  margin: 0;
}
.hero-content-div .btn {
  margin-left: 1.5rem;
}
.hero-me {
  width: 380px;
  margin-bottom: -120px;
  margin-left: 50px;
}
.hero-card {
  display: flex;
  align-items: center;
  position: absolute;
  background-color: #fff;
  color: #6F6F6F;
  padding: 1.5rem;
  border-radius: 3px;
  box-shadow: 0px 11px 50px rgba(0, 0, 0, 0.2);
}
.hero-card1 {
  right: calc(15vw - 3vw);
  bottom: 290px;
}
.hero-card2 {
  right: calc(15vw - 3vw + 3rem);
  bottom: 150px;
}
.hero-card-p {
  margin: 0;
  width: 250px;
  font-weight: 600;
}
.hero-card-p .dis {
  color: #FF3D6B;
  font-weight: 800;
}
.hero-card-p .prix {
  color: #FFB21D;
  font-weight: 800;
}
.hero-card-svg {
  width: 2rem;
  height: 2rem;
  margin-right: 1.5rem;
}
.hero-bg {
  z-index: -100;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.hero-bg-vawes {
  display: block;
  z-index: 1000;
  position: absolute;
  bottom: 10px;
  width: 100%;
  height: 70vh;
}
.hero-bg-picto {
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 1000;
  -webkit-mask-image: radial-gradient(ellipse 100% 140% at 50% 50%, transparent 0%, #000000b9 100%);
  mask-image: radial-gradient(ellipse 100% 140% at 50% 50%, transparent 0%, #000000b9 100%);
}
.hero-bg-pictoAnim {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.hero-bg-pictoAnimLeft {
  left: 0;
}
.hero-bg-pictoAnimRight {
  right: 0;
}
.hero-bg-pictoAnim1 {
  animation: float1 5s infinite alternate cubic-bezier(0.37, 0, 0.63, 1);
}
.hero-bg-pictoAnim2 {
  animation: float2 5s infinite alternate cubic-bezier(0.37, 0, 0.63, 1);
}
.hero-bg-pictoAnim3 {
  animation: float3 5s infinite alternate cubic-bezier(0.37, 0, 0.63, 1);
}
.hero-bg-picto img {
  position: absolute;
  bottom: 0;
  height: 100%;
}
.hero-bg:before {
  z-index: calc(-100 - 50);
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: radial-gradient(circle at -10% 20%, #ffffffd2 0%, transparent 30%), radial-gradient(circle at 100% 20%, #ffffffee 0%, transparent 25%), radial-gradient(circle at 70% 110%, #ffffffe7 0%, transparent 50%), linear-gradient(to right, #FFA217, #F4598F, #48A7FF);
}
.hero-transition {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80px;
  color: #fff;
}

.portfolio {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 500;
  position: relative;
  margin: 1rem;
  height: calc(550px + 10vw);
  background-color: #3b3b3b;
  border-radius: 10px;
  box-shadow: inset 0px 10px 55px rgba(0, 0, 0, 0.3);
  transition: all 0.6s cubic-bezier(0.33, 1, 0.68, 1);
  will-change: background-color;
}
.portfolio:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 110%;
  background: linear-gradient(45deg, transparent, #ffffff6e);
  mix-blend-mode: lighten;
}
.portfolio-buttons {
  z-index: calc(500 + 5);
  position: absolute;
  margin-top: 340px;
  margin-left: calc((100vw - 1200px) / 2 - 5.5rem);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(1200px + 10rem);
  color: #fff;
  will-change: background-color;
}
.portfolio-el {
  position: absolute;
  display: flex;
  border-radius: 10px;
  background-color: #fff;
  height: 500px;
  width: 1200px;
  pointer-events: none;
  box-shadow: 0px 30px 77px #00000033;
  will-change: opacity, transform;
  opacity: 0;
}
.portfolio-el-div {
  position: relative;
  width: 50%;
  padding: 3rem;
  box-sizing: border-box;
}
.portfolio-el-div .btn {
  position: absolute;
  bottom: 3rem;
  color: #fff;
}
.portfolio-el-div h3 {
  margin: 0;
}
.portfolio-el-div span {
  margin: 0;
}
.portfolio-el-image {
  z-index: 500;
  position: relative;
  width: 50%;
  height: 100%;
  box-sizing: border-box;
  border-radius: 10px;
  overflow: hidden;
  transition: all 0.6s cubic-bezier(0.33, 1, 0.68, 1);
}
.portfolio-el-image:before {
  content: "";
  position: absolute;
  z-index: calc(500 - 5);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(#0000007c, transparent);
  mix-blend-mode: darken;
}
.portfolio-el-image-shadow {
  position: absolute;
  z-index: calc(500 + 5);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: inset 0px 0px 50px rgba(0, 0, 0, 0.1);
}
.portfolio-el-image-img {
  position: relative;
  z-index: calc(500 - 5);
  object-fit: cover;
  transform: scale(1);
  transition: transform 15s cubic-bezier(0.61, 1, 0.88, 1);
}
.portfolio-el.actual {
  opacity: 1;
  pointer-events: inherit;
}
.portfolio-el.actual .portfolio-el-image-img {
  transform: scale(1.1);
}
.portfolio-indicator {
  z-index: calc(500 + 5);
  position: absolute;
  margin-top: calc(-3vw - 20px);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 20px;
  pointer-events: none;
}
.portfolio-indicator-el {
  width: 10px;
  height: 10px;
  background-color: #fff;
  border-radius: 1000px;
  margin: 10px;
  border: 0px solid #fff;
  opacity: 0.5;
  transition: all 0.3s cubic-bezier(0.33, 1, 0.68, 1);
}
.portfolio-indicator-el.actual {
  opacity: 1;
  margin: 6px;
  border: 4px solid #fff;
}

.portfolio[color="0"] {
  background-color: var(--c-0);
}

.portfolio[color="1"] {
  background-color: var(--c-1);
}

.portfolio[color="2"] {
  background-color: var(--c-2);
}

.portfolio[color="3"] {
  background-color: var(--c-3);
}

.portfolio[color="4"] {
  background-color: var(--c-4);
}

.portfolio[color="5"] {
  background-color: var(--c-5);
}

.hiding-left {
  animation: hideRight 0.45s;
  animation-timing-function: cubic-bezier(0.61, 1, 0.88, 1);
}

.hiding-right {
  animation: hideLeft 0.45s;
  animation-timing-function: cubic-bezier(0.61, 1, 0.88, 1);
}

.showing-left {
  animation: showLeft 0.45s;
  animation-timing-function: cubic-bezier(0.61, 1, 0.88, 1);
}

.showing-right {
  animation: showRight 0.45s;
  animation-timing-function: cubic-bezier(0.61, 1, 0.88, 1);
}

#about {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}
#about .about-footPrints {
  position: absolute;
  z-index: -100;
  width: 110%;
  margin-top: -6rem;
  left: -5%;
}

.timeline {
  display: flex;
  justify-content: center;
  width: calc(100% - 6rem);
  margin-left: 3rem;
  margin-top: 81px;
}
.timeline-el {
  border-radius: 10px;
  padding: 1rem;
  box-sizing: border-box;
  width: auto;
  color: #fff;
  margin-right: 1.5rem;
  position: relative;
}
.timeline-el:last-child {
  margin-right: 0;
}
.timeline-el:before {
  position: absolute;
  height: 50px;
  width: 280px;
  max-width: 100%;
}
.timeline-el:after {
  content: "";
  display: block;
  position: absolute;
  bottom: -1.5rem;
  width: calc(100% + 1.5rem);
  height: 1px;
  margin-left: -1rem;
  border-bottom: 2px solid #dddddd;
}
.timeline-el:nth-child(1) {
  width: 220px !important;
  min-width: 220px !important;
  background: linear-gradient(to right, #FFA217, #FA8447);
}
.timeline-el:nth-child(1)::before {
  content: url("../assets/timeline1.svg");
  width: 125px;
  top: -66px;
  right: 20px;
}
.timeline-el:nth-child(1)::after {
  width: calc(100% + 3.8rem);
  margin-left: -1.8rem;
}
.timeline-el:nth-child(2) {
  background: linear-gradient(to right, #F97F50, #F45A8D);
}
.timeline-el:nth-child(2)::before {
  content: url("../assets/timeline2.svg");
  top: -87px;
  right: 10%;
}
.timeline-el:nth-child(3) {
  background: linear-gradient(to right, #C36FAE, #48A6FE);
}
.timeline-el:nth-child(3)::before {
  content: url("../assets/timeline3.svg");
  top: -75px;
  right: 10px;
}
.timeline-el:nth-child(3):after {
  width: calc(100% + 0.75rem);
}
.timeline-el-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 190px;
  color: #48A6FE;
  background-color: #fff;
  border-radius: 10px;
  border: 2px solid #48A6FE;
}
.timeline-el-btn .btn {
  width: 100%;
  height: 100%;
}
.timeline-el-btn i {
  font-size: 24px;
  align-self: flex-start;
}
.timeline-el-btn:after {
  display: none;
}
.timeline-el-date {
  position: absolute;
  color: #6F6F6F;
  font-weight: 700;
  bottom: 0;
  margin-bottom: -5rem;
  left: -2rem;
  color: #dddddd;
  display: flex;
  justify-content: center;
}
.timeline-el-date::before {
  content: "";
  height: 0.75rem;
  width: 1px;
  border-left: 2px solid #dddddd;
  position: absolute;
  top: -2rem;
}
.timeline-el-text p {
  font-weight: 500;
  margin: 0;
}
.timeline-el-textSmall {
  margin-top: 0.5rem !important;
  opacity: 0.8;
  font-size: 11px;
  font-weight: 600;
}

.about-text {
  display: flex;
  margin: 10rem 2rem;
}
.about-text p {
  width: 33.33%;
  margin-right: 2rem;
  max-width: 450px;
}
.about-text p:last-child {
  margin-right: 0;
}

.contact {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 55px;
  left: 0;
  z-index: calc(1000 - 10);
  width: 100vw;
  height: calc(100vh - 55px);
  overflow: hidden;
}
.contact .mailSend {
  position: fixed;
  z-index: calc(1000 - 5);
  top: calc(55px + 1.5rem);
  margin: 0 auto;
  padding: 1.5rem;
  background-color: #fff;
  box-shadow: 0px 30px 77px #00000033;
  border-radius: 3px;
  transform: translateY(100px);
  opacity: 0;
  pointer-events: none;
}
.contact .mailSend .valid {
  color: #8bac4e;
}
.contact .mailSend .invalid {
  color: #f8423c;
}
.contact .mailSend.anim {
  animation: fadeInAndOut 4s;
}
.contact .wrapper {
  width: 100%;
}
.contact-div {
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 30px 77px #00000033;
}
.contact-div .form {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  padding: 3rem;
  padding-bottom: 0;
}
.contact-div .form-header {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;
  width: 100%;
  padding: 3rem;
  padding-bottom: 1.5rem;
  padding-top: 2.5rem;
  margin-bottom: 2.5rem;
  border-bottom: 1px solid #dddddd;
}
.contact-div .form-header h2 {
  margin: 0;
}
.contact-div .form-header button {
  font-size: 24px;
}
.contact-div .form-row {
  display: flex;
  margin-bottom: 1.5rem;
}
.contact-div .form-row .form-field {
  margin-right: 3rem;
}
.contact-div .form-row .form-field:last-child {
  margin-right: 0;
}
.contact-div .form-field {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
}
.contact-div .form-field #feedback {
  position: absolute;
}
.contact-div .form-field-input {
  margin-top: 1rem;
  padding: 0.75rem;
  border: 1px solid #dddddd;
  border-radius: 3px;
  resize: none;
  cursor: text;
}
.contact-div .form-field-input.invalid {
  border: 1px solid #ff2a00;
}
.contact-div .form-field-textarea {
  height: 5rem;
}
.contact-div .form .btn-submit {
  margin-top: 2rem;
}
.contact-info {
  margin-top: 3rem;
  padding-bottom: 3rem;
  display: flex;
  justify-content: center;
}
.contact-info-el {
  padding: 1.5rem;
  margin-right: 3rem;
}
.contact-info-el:last-child {
  margin-right: 0;
}
.contact-info-el p {
  margin: 0;
}

body .contact {
  transition: all 0.3s cubic-bezier(0.33, 1, 0.68, 1);
  pointer-events: none;
  background-color: transparent;
}
body .contact-div {
  transform: translateY(90%) scale(0.6) rotateX(-90deg);
}
body.contactOpening .contact {
  background-color: #0000009f;
  pointer-events: inherit;
}
body.contactOpening .contact-div {
  animation: contactOpening 0.5s;
}
body.contactFormOpen {
  position: fixed;
  width: 100%;
}
body.contactFormOpen .contact {
  background-color: #0000009f;
  pointer-events: inherit;
}
body.contactFormOpen .contact-div {
  opacity: 1;
  transform: inherit;
}
body.contactClosing .contact {
  background-color: transparent;
}
body.contactClosing .contact-div {
  animation: contactClosing 0.3s;
}

.footer {
  width: 100%;
  padding: 1rem 0;
  text-align: center;
  box-shadow: 0px 30px 77px #00000033;
}
.footer p {
  margin: 0;
}

@media (max-width: 1460px) {
  .hero-content-div {
    flex-direction: column;
    align-items: flex-start;
    width: 500px;
  }
  .hero-content-div .btn {
    margin-left: 0;
    margin-top: 1.5rem;
  }

  .hero {
    padding-bottom: 10rem;
    padding-top: 10rem;
  }
  .hero-content-title {
    line-height: 0.5;
    margin: 5rem 0;
    width: 30vw;
  }
  .hero-me {
    position: absolute;
    right: 0px;
    z-index: -100;
    height: 800px;
    width: auto;
    bottom: 0;
  }

  .portfolio-el {
    width: 80%;
  }

  .portfolio-buttons {
    width: calc(80% + 8rem);
    margin-left: calc((100vw - 80%) / 2 - 4.5rem);
  }

  .wrapper {
    width: 100%;
  }
}
@media (max-width: 1160px) {
  .hero-bg-picto {
    display: none;
  }

  .header {
    box-sizing: border-box;
    padding: 0.75rem 1.5rem;
  }

  .hero {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
@media (max-width: 900px) {
  .desktop {
    display: none !important;
  }

  .mobile {
    display: flex !important;
  }

  .btn-menu {
    height: 100%;
    width: 24px;
  }
  .btn-menu i {
    position: absolute;
    font-size: 24px;
  }
  .btn-menu .fa-bars {
    transition: all 0.3s cubic-bezier(0.33, 1, 0.68, 1);
    opacity: 1;
  }
  .btn-menu .fa-times {
    transition: all 0.3s cubic-bezier(0.33, 1, 0.68, 1);
    opacity: 0;
  }

  .header {
    padding-top: 1.2rem;
  }
  .header.scrolled .menu-el:nth-child(3) {
    border: none;
  }

  .menu {
    position: fixed;
    box-sizing: border-box;
    top: 5rem;
    left: 2rem;
    width: calc(100% - 4rem);
    box-shadow: 0px 30px 77px #00000033;
    border-radius: 3px;
    background-color: #fff;
    color: #6F6F6F !important;
    z-index: calc(-100 - 5);
    transform: translateY(50px) rotateX(-90deg);
    transition: all 0.3s cubic-bezier(0.33, 1, 0.68, 1);
    opacity: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .menu-el {
    margin: 0 !important;
    text-align: center;
    padding: 1rem;
  }

  body.menu--open .btn-menu .fa-bars {
    opacity: 0;
  }
  body.menu--open .btn-menu .fa-times {
    opacity: 1;
  }
  body.menu--open .menu {
    opacity: 1;
    transform: translateY(0) rotateX(0);
  }

  .hero {
    height: 75vh;
    padding-top: 5rem;
  }
  .hero-content {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .hero-content-div {
    position: absolute;
    padding: 1.5rem;
    justify-self: flex-end;
    text-align: center;
    align-items: center;
    border-radius: 10px;
    width: auto;
    bottom: 0px;
    padding-bottom: 3rem;
  }
  .hero-content-title {
    width: 100%;
    margin-top: 5rem;
    font-size: 120px;
  }
  .hero-content-titleSecond {
    font-size: 36px;
  }
  .hero-card1 {
    right: inherit;
    left: 2rem;
    bottom: calc(30vh + 9rem);
  }
  .hero-card2 {
    right: inherit;
    left: 5rem;
    bottom: 30vh;
  }
  .hero-me {
    height: 75vh;
  }

  .portfolio {
    padding-bottom: 100px;
    height: 800px;
  }
  .portfolio-el {
    flex-direction: column-reverse;
    height: auto;
  }
  .portfolio-el-div {
    width: 100%;
  }
  .portfolio-el-div p {
    margin-bottom: 1.5rem;
  }
  .portfolio-el-div .btn {
    position: relative;
    display: inline-flex;
    transform: translateY(3rem);
    align-self: flex-start;
  }
  .portfolio-el-image {
    width: 100%;
    height: 300px;
  }
  .portfolio-el-image-img {
    margin-top: -10%;
  }
  .portfolio-indicator {
    margin-top: -5rem;
  }
  .portfolio-buttons {
    box-sizing: border-box;
    margin: 0;
    margin-left: 10%;
    margin-right: 10%;
    width: 80%;
    margin-top: 805px;
  }

  .timeline {
    flex-direction: column;
    width: 70%;
    margin-left: 10%;
    margin: 5rem 0;
  }
  .timeline-el {
    margin-bottom: 7rem;
  }
  .timeline-el:last-child {
    margin-bottom: 0;
  }
  .timeline-el:nth-child(1)::after {
    margin-left: -2rem;
  }
  .timeline-el:nth-child(3) {
    margin-bottom: 6rem;
  }
  .timeline-el:nth-child(3)::after {
    height: calc(100% + 6.2rem);
  }
  .timeline-el:nth-child(4) {
    height: 120px;
    width: 120px;
  }
  .timeline-el:after {
    width: 1px;
    height: calc(100% + 8.25rem);
    top: 0;
    margin: 0;
    margin-left: -2rem;
    margin-top: -3.4rem;
    border: none;
    border-left: 2px solid #dddddd;
  }
  .timeline-el-date {
    left: 0.1rem;
    margin-top: -4rem;
    top: 0;
    display: block;
  }
  .timeline-el-date::before {
    width: 0.5rem;
    height: 1px;
    margin-top: 0.6rem;
    border-left: none;
    border-top: 2px solid #dddddd;
    top: inherit;
    left: -1.1rem;
  }
  .timeline-el-btn-date {
    margin-top: inherit;
  }

  .about-text {
    flex-direction: column;
    margin: 0 1.5rem;
    margin-bottom: 3rem;
    margin-top: 0;
  }
  .about-text p {
    width: inherit;
  }

  #about .wrapper {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }

  .pretty-bg:after {
    z-index: -100;
    mask-image: -webkit-gradient(linear, left center, right top, from(rgba(0, 0, 0, 0.06)), to(rgba(0, 0, 0, 0.01)));
    -webkit-mask-image: -webkit-gradient(linear, left center, right top, from(rgba(0, 0, 0, 0.06)), to(rgba(0, 0, 0, 0.01)));
  }

  .contact-div {
    box-sizing: border-box;
    margin: 2rem;
    width: calc(100% - 4rem);
  }
}
@media (max-width: 700px) {
  .hero-me {
    right: -100px;
  }

  .form-row {
    margin-bottom: 1rem;
    flex-direction: column;
  }
  .form-row .form-field {
    margin-bottom: 1.5rem;
  }
  .form-row .form-field:last-child {
    margin-bottom: inherit;
  }
}
@media (max-width: 500px) {
  .hero {
    overflow-y: visible;
    height: 690px;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
  }
  .hero-content-title {
    font-size: 20vw;
    line-height: 12.5vw;
    margin-top: 2rem;
    margin-bottom: 2rem;
    text-shadow: 0px 30px 77px #00000033;
  }
  .hero-content-titleSecond {
    font-size: 6vw;
  }
  .hero-card {
    top: inherit;
    bottom: inherit;
    left: inherit;
    right: inherit;
    box-sizing: border-box;
    width: 300px;
    max-width: 100%;
    padding: 1rem;
    margin: 1rem 0;
  }
  .hero-card1 {
    bottom: 12rem;
    align-self: flex-end;
  }
  .hero-card2 {
    bottom: 20rem;
    align-self: flex-start;
  }
  .hero-card-svg {
    padding: 0 1rem;
  }
  .hero-me {
    height: 600px;
    right: inherit;
    margin-bottom: 100px;
  }
  .hero-transition {
    height: 50px;
  }
  .hero-bg {
    background-image: radial-gradient(circle at -20% 5%, #ffffff91 0%, transparent 30%), radial-gradient(circle at 100% 50%, #ffffffa1 0%, transparent 25%), radial-gradient(circle at 50% 90%, #ffffff86 0%, transparent 50%), linear-gradient(to right, #FFA217, #F4598F, #48A7FF);
  }

  .timeline {
    width: 90%;
    margin-left: 10%;
  }

  .portfolio {
    margin: 0;
    width: 100%;
  }
  .portfolio-el {
    width: 350px;
    max-width: 100%;
  }
  .portfolio-el-div {
    padding: 2rem;
  }

  .contact {
    overflow-y: scroll;
  }
  .contact .wrapper {
    height: calc(100% + 2rem);
    box-sizing: border-box;
    padding: 2rem 0;
  }
  .contact-div {
    width: calc(100vw - 2rem);
    margin-top: 30rem;
    margin: 0 1rem;
  }
  .contact .form {
    padding: 1.5rem;
    padding-bottom: 0;
  }
  .contact .form-header {
    padding: 1.5rem;
    margin-bottom: 0;
  }
  .contact .form-header h2 {
    margin-bottom: -0.5rem;
  }
  .contact-info {
    flex-direction: column;
  }
  .contact-info-el {
    justify-content: center;
    align-items: center;
    margin-bottom: 1.5rem;
    margin-left: 4rem;
    margin-right: 4rem !important;
  }
  .contact-info-el:last-child {
    margin-bottom: 0;
  }
  .contact-info-el .btn {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}