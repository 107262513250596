@keyframes hideLeft {
    from {transform: translateX(0%) scale(1); opacity: 1;}
    to {transform: translateX(-110%) scale(0.6); opacity: 0;}
}

@keyframes hideRight {
    from {transform: translateX(0%) scale(1); opacity: 1;}
    to {transform: translateX(110%) scale(0.6); opacity: 0;}
}

@keyframes showLeft {
    from {transform: translateX(-110%) scale(0.6); opacity: 0;}
    to {transform: translateX(0%) scale(1); opacity: 1;}
}

@keyframes showRight {
    from {transform: translateX(110%) scale(0.6); opacity: 0;}
    to {transform: translateX(0%) scale(1); opacity: 1;}
}

@keyframes hover {
    0% {transform: scale(0.2); opacity: 0;}
    10% {opacity: 1;}
    100% {transform: scale(15); opacity: 0;}
}

@keyframes contactOpening {
    from {transform: translateY(90%) scale(0.6) rotateX(-90deg); opacity: 0;}
    to {transform: translateY(0%) scale(1) rotateX(0deg); opacity: 1;}
}

@keyframes contactClosing {
    from {opacity: 1;}
    to {opacity: 0;}
}

@keyframes float1 {
    from {transform: translateY(0px);}
    to {transform: translateY(30px);}
}

@keyframes float2 {
    from {transform: translateY(-10px);}
    to {transform: translateY(30px);}
}

@keyframes float3 {
    from {transform: translateY(30px);}
    to {transform: translateY(0px);}
}

@keyframes fadeInAndOut {
    0% {transform: translateY(100px); opacity: 0;}
    10% {transform: translateY(0px); opacity: 1;}
    90% {transform: translateY(0px); opacity: 1;}
    100% {transform: translateY(100px); opacity: 0;}
}