.useSvg {
    display: none;
}

.mobile {display: none!important;}

// Si le navigateur ne supporte pas la "backdrop filter", on remplace avec un fond plus opaque
@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    .header, .hero-content-div {
        backdrop-filter: blur(30px);
    }
}
  
/* slightly transparent fallback for Firefox (not supporting backdrop-filter) */
@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    .header {
        background-color: #000000ce;
    }
}

html, body, section {
    overflow-x: hidden;
    scroll-behavior: smooth;
}

* {
    &::-webkit-scrollbar {
        width: 0px;
    
        /*&-track {
            background: #fff; 
        }

        &-thumb {
            background: #c7c7c7;
            border-radius: $round;
        }
        
        &-thumb:hover {
            background: #a3a3a3; 
        }*/
    }
}

.wrapper {
    max-width: $site-width;
}

.pretty-bg {
    position: relative;
    overflow: hidden;
    &:after {
        z-index: $behind;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('../assets/graph.svg');
        background-size: 3rem;
        color: $white;
        mask-image: -webkit-gradient(linear, left bottom, right top, from(rgba(0, 0, 0, 0.06)), to(rgba(0,0,0,0.01)));
        -webkit-mask-image: -webkit-gradient(linear, left bottom, right top, from(rgba(0, 0, 0, 0.06)), to(rgba(0,0,0,0.01)));
    }
}


.btn {
    padding: 0.7rem 1rem;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: $rounded;
    font-weight: 600;
    cursor: pointer;

    position: relative;
    overflow: hidden;

    &-white {
        border: 1px solid $light-dark;
    }

    &-picto {
        @include flex-center-x;
        i {
            margin-right: 0.75rem;
        }
    }

    transition: $t-fast;
    &:active {
        transform: scale(0.95);
    }

    .hover {
        position: absolute;
        border-radius: $round;
        background-color: rgba(0, 0, 0, 0.075);
        animation: hover 1.5s;
        width: 100px;
        height: 100px;
        margin-top: -50px;
        margin-left: -50px;
    }
}


.header {
    @include flex-center-y;
    .wrapper {
        @include flex-center-x;
        width: 100%;
        justify-content: space-between;
    }


    z-index: $top;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    background-color: $light-dark;
    box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.1);
    color: $white;

    padding: 0.75rem 0;

    &-logo {
        width: $h4;
        height: $h4;
    }

    .menu {
        @include flex-center-x;
        &-el {
            margin-right: 3rem;
            &:last-child {
                margin-right: 0;
            }
        }
    }


    transition: $t-slow;
    &.scrolled {
        background-color: $white;
        color: $c-text;

        .menu-el:last-child {
            border: 1px solid $light-dark;
            border-radius: $rounded;
        }
    }
}

.hero {
    @include flex-center-y;
    .wrapper {
        display: flex;
        width: 100%;
    }



    position: relative;
    overflow: hidden;
    padding: 12rem 0 5rem 0;
    color: $white;

    &-content {
        display: flex;
        flex-direction: column;
        &-title {
            line-height: calc(#{$h2} + 1.5rem);
            &Second {
                font-size: $h3;
                font-family: 'Montserrat', sans-serif;
            }
        }

        &-div {
            @include flex-center-x;
            justify-content: space-between;
            padding: 1rem;
            padding-left: 1.5rem;
            background-color: $light-dark;
            border-radius: $rounded;
            box-shadow: 0px 10px 32px rgba(0, 0, 0, 0.1);

            & p {
                margin: 0;
            }

            .btn {
                margin-left: 1.5rem;
            }
        }
    }

    &-me {
        width: 380px;
        margin-bottom: -120px;
        margin-left: 50px;
    }

    &-card {
        @include flex-center-x;
        position: absolute;
        background-color: $white;
        color: $c-text;
        padding: 1.5rem;
        border-radius: $rounded;
        box-shadow: 0px 11px 50px rgba(0, 0, 0, 0.2);

        &1 {
            right: calc(#{$desktop-gap} - 3vw);
            bottom: 290px;
        }

        &2 {
            right: calc(#{$desktop-gap} - 3vw + 3rem);
            bottom: 150px;
        }

        &-p {
            margin: 0;
            width: 250px;
            font-weight: 600;

            .dis {
                color: #FF3D6B;
                font-weight: 800;
            }

            .prix {
                color: #FFB21D;
                font-weight: 800;
            }
        }

        &-svg {
            width: 2rem;
            height: 2rem;
            margin-right: 1.5rem;
        }
    }
    
    &-bg {
        z-index: $behind;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        &-vawes {
            display: block;
            z-index: $top;
            position: absolute;
            bottom: 10px;
            width: 100%;
            height: 70vh;
        }

        &-picto {
            position: absolute;
            height: 100%;
            width: 100%;
            bottom: 0;
            left: 0;
            z-index: $top;
            -webkit-mask-image: radial-gradient(ellipse 100% 140% at 50% 50%, transparent 0%, #000000b9 100%);
            mask-image: radial-gradient(ellipse 100% 140% at 50% 50%, transparent 0%, #000000b9 100%);
            
            &Anim {
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 100%;

                &Left {
                    left: 0;
                }
                &Right {
                    right: 0;
                }
            }
            &Anim1 {
                animation: float1 5s infinite alternate cubic-bezier(0.37, 0, 0.63, 1);;
            }
            &Anim2 {
                animation: float2 5s infinite alternate cubic-bezier(0.37, 0, 0.63, 1);;
            }
            &Anim3 {
                animation: float3 5s infinite alternate cubic-bezier(0.37, 0, 0.63, 1);;
            }

            img {
                position: absolute;
                bottom: 0;
                height: 100%;
            }
        }

        &:before {
            z-index: calc(#{$behind} - 50);
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            
            background-image: radial-gradient(circle at -10% 20%, #ffffffd2 0%, transparent 30%),
                                radial-gradient(circle at 100% 20%, #ffffffee 0%, transparent 25%),
                                radial-gradient(circle at 70% 110%, #ffffffe7 0%, transparent 50%),
                                $gradient-linear;
        }
    }

    &-transition {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 80px;
        color: $white;
    }
}

.portfolio {
    @include flex-center;
    z-index: $between;
    position: relative;
    margin: 1rem;
    height: calc(550px + 10vw);
    background-color: rgb(59, 59, 59);
    border-radius: $rounded2;
    box-shadow: inset 0px 10px 55px rgba(0, 0, 0, 0.30);

    transition: $t-slow;
    will-change: background-color;

    &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 110%;
        background: linear-gradient(45deg, transparent, #ffffff6e);
        mix-blend-mode: lighten;
    }

    &-buttons {
        z-index: calc(#{$between} + 5);
        position: absolute;
        margin-top: 340px;
        margin-left: calc((100vw - #{$site-width}) / 2 - 5.5rem);
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: calc(#{$site-width} + 10rem);
        color: $white;
        will-change: background-color;
    }

    &-el {
        position: absolute;
        display: flex;
        border-radius: $rounded2;
        background-color: $white;
        height: 500px;
        width: $site-width;
        pointer-events: none;
        box-shadow: $shadow;
        will-change: opacity, transform;

        &-div {
            position: relative;
            width: 50%;
            padding: 3rem;
            box-sizing: border-box;

            .btn {
                position: absolute;
                bottom: 3rem;
                color: $white;
            }

            h3 {
                margin: 0;
            }

            span {
                margin: 0;
            }
        }

        &-image {
            z-index: $between;
            position: relative;
            width: 50%;
            height: 100%;
            box-sizing: border-box;
            border-radius: $rounded2;
            overflow: hidden;
            transition: $t-slow;

            &:before {
                content: "";
                position: absolute;
                z-index: calc(#{$between} - 5);
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: radial-gradient(#0000007c, transparent);
                mix-blend-mode: darken;
            }

            &-shadow {
                position: absolute;
                z-index: calc(#{$between} + 5);
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                box-shadow: inset 0px 0px 50px rgba(0, 0, 0, 0.10);
            }
            
            &-img {
                position: relative;
                z-index: calc(#{$between} - 5);
                object-fit: cover;
                transform: scale(1);
                transition: transform 15s cubic-bezier(0.61, 1, 0.88, 1);
            }
        }
        
        opacity: 0;
        &.actual {
            opacity: 1;
            pointer-events: inherit;
            .portfolio-el-image-img {
                transform: scale(1.1);
            }
        }
    }

    &-indicator {
        z-index: calc(#{$between} + 5);
        position: absolute;
        margin-top: calc(-3vw - 20px);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 20px;
        pointer-events: none;
        &-el {
            width: 10px;
            height: 10px;
            background-color: $white;
            border-radius: $round;
            margin: 10px;
            border: 0px solid $white;
            opacity: .5;
            transition: $t-fast;
            &.actual {
                opacity: 1;
                margin: 6px;
                border: 4px solid $white;
            }
        }
    }
}

@for $i from 0 through 5 {
    .portfolio[color='#{$i}'] {
        background-color: var(--c-#{$i});
    }
}

.hiding-left {
    animation: hideRight 0.45s;
    animation-timing-function: cubic-bezier(0.61, 1, 0.88, 1);
}

.hiding-right {
    animation: hideLeft 0.45s;
    animation-timing-function: cubic-bezier(0.61, 1, 0.88, 1);
}

.showing-left {
    animation: showLeft 0.45s;
    animation-timing-function: cubic-bezier(0.61, 1, 0.88, 1);
}

.showing-right {
    animation: showRight 0.45s;
    animation-timing-function: cubic-bezier(0.61, 1, 0.88, 1);
}

#about {
    @include flex-column-center-y;
    overflow-x: hidden;
    .about-footPrints {
        position: absolute;
        z-index: $behind;
        width: 110%;
        margin-top: -6rem;
        left: -5%;
    }
}

.timeline {
    display: flex;
    justify-content: center;
    width: calc(100% - 6rem);
    margin-left: 3rem;

    margin-top: $h1;
    &-el {
        border-radius: $rounded2;
        padding: 1rem;
        box-sizing: border-box;
        width: auto;
        color: $white;
        margin-right: 1.5rem;
        position: relative;

        &:last-child {
            margin-right: 0;
        }

        &:before {
            position: absolute;
            height: 50px;
            width: 280px;  
            max-width: 100%;          
        }

        &:after {
            content: "";
            display: block;
            position: absolute;
            bottom: -1.5rem;
            width: calc(100% + 1.5rem);
            height: 1px;
            margin-left: -1rem;
            border-bottom: 2px solid $light-grey;
        }

        &:nth-child(1) {
            width: 220px!important;
            min-width: 220px!important;
            background: linear-gradient(to right, #FFA217, #FA8447);
            &::before{content:url('../assets/timeline1.svg'); 
                width: 125px; top: -66px; right: 20px;
            }

            &::after {
                width: calc(100% + 3.8rem);
                margin-left: -1.8rem;
            }
        }
        &:nth-child(2) {
            background: linear-gradient(to right, #F97F50, #F45A8D);
            &::before{content:url('../assets/timeline2.svg');
                top: -87px;
                right: 10%;
            }
        }
        &:nth-child(3) {
            background: linear-gradient(to right, #C36FAE,$blue);
            &::before{content:url('../assets/timeline3.svg');
                top: -75px;
                right: 10px;
            }
            &:after {
                width: calc(100% + 0.75rem);
            }
        }

        &-btn {
            @include flex-center;
            padding: 0;
            width: 190px;
            color: $blue;
            background-color: $white;
            border-radius: $rounded2;
            border: 2px solid $blue;
            .btn {width: 100%; height: 100%;}
            i {font-size: $h4; align-self: flex-start;}

            &:after {
                display: none;
            }
        }

        &-date {
            position: absolute;
            color: $c-text;
            font-weight: 700;
            bottom: 0;
            margin-bottom: -5rem;
            left: -2rem;
            color: $light-grey;

            display: flex;
            justify-content: center;
            &::before {
                content: "";
                height: 0.75rem;
                width: 1px;
                border-left: 2px solid $light-grey;
                position: absolute;
                top: -2rem;
            }
        }

        &-text {
            p{
                font-weight: 500;
                margin: 0;
            }
        } 

        &-textSmall {
            margin-top: 0.5rem!important;
            opacity: .8;
            font-size: 11px;
            font-weight: 600;
        }
    }
}

.about-text {
    display: flex;
    margin: 10rem 2rem;
    & p {
        width: 33.33%;
        margin-right: 2rem;
        max-width: 450px;
        &:last-child {
            margin-right: 0;
        }
    }
}

.contact {
    @include flex-center;
    position: fixed;
    top: 55px;
    left: 0;
    z-index: calc(#{$top} - 10);
    width: 100vw;
    height: calc(100vh - 55px);
    overflow: hidden;

    .mailSend {
        position: fixed;
        z-index: calc(#{$top} - 5);
        top: calc(55px + 1.5rem);
        margin: 0 auto;
        padding: 1.5rem;
        background-color: $white;
        box-shadow: $shadow;
        border-radius: $rounded;
        transform: translateY(100px);
        opacity: 0;
        pointer-events: none;
        

        & .valid {color: rgb(139, 172, 78);}
        & .invalid {color: rgb(248, 66, 60);}

        &.anim {animation: fadeInAndOut 4s;}
    }

    .wrapper {
        width: 100%;
    }

    &-div {
        width: 100%;
        background-color: $white;
        border-radius: $rounded2;
        box-shadow: $shadow;

        .form {
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            width: 100%;
            padding: 3rem;
            padding-bottom: 0;

            &-header {
                @include flex-center-x;
                box-sizing: border-box;
                justify-content: space-between;
                width: 100%;
                padding: 3rem;
                h2 {margin: 0;}
                button {font-size: $h4;}

                padding-bottom: 1.5rem;
                padding-top: 2.5rem;
                margin-bottom: 2.5rem;
                border-bottom: 1px solid $light-grey;
            }

            &-row {
                display: flex;
                margin-bottom: 1.5rem;
                .form-field {
                    margin-right: 3rem;
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }

            &-field {
                #feedback {position: absolute;}
                display: flex;
                flex-direction: column;
                width: 100%;
                box-sizing: border-box;
                
                &-input {
                    margin-top: 1rem;
                    padding: 0.75rem;
                    border: 1px solid $light-grey;
                    border-radius: $rounded;
                    resize: none;
                    cursor: text;

                    &.invalid {
                        border: 1px solid #ff2a00;
                    }
                }

                &-textarea {
                    height: 5rem;
                }
            }

            .btn-submit {
                margin-top: 2rem;
            }
        }
    }

    &-info {
        margin-top: 3rem;
        padding-bottom: 3rem;
        display: flex;
        justify-content: center;
        &-el {
            padding: 1.5rem;
            margin-right: 3rem;
            &:last-child {margin-right: 0;}
            p {margin: 0;}
        }
    }

}

body {
    .contact {
        transition: $t-fast;
        pointer-events: none;
        background-color: transparent;
        &-div {
            transform: translateY(90%) scale(0.6) rotateX(-90deg);
        }
    }

    &.contactOpening {
        .contact {
            background-color: #0000009f;
            pointer-events: inherit;
            &-div {
                animation: contactOpening 0.5s;
            }
        }
    }

    &.contactFormOpen {
        position: fixed;
        width: 100%;
        .contact {
            background-color: #0000009f;
            pointer-events: inherit;
            &-div {
                opacity: 1;
                transform: inherit;
            }
        }
    }

    &.contactClosing {
        .contact {
            background-color: transparent;
            &-div {
                animation: contactClosing 0.3s;
            }
        }
    }
}

.footer {
    width: 100%;
    padding: 1rem 0;
    text-align: center;
    box-shadow: $shadow;

    & p {
        margin: 0;
    }
}