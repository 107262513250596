body {
  margin: 0;
}

img {
  display: block;
}

a {
  color: inherit;
  text-decoration: none;
}

button, input, textarea {
    background-color: transparent;
    font-size: inherit;
    color: inherit;
    font-weight: inherit;
    border: none;
    font-family: inherit;
    &:focus {
      outline: none;
    }
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}