// Flex rows
@mixin flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-center-x {
    display: flex;
    align-items: center;
}

@mixin flex-center-y {
    display: flex;
    justify-content: center;
}

// Flex columns
@mixin flex-column {
    display: flex;
    flex-direction: column;
}

@mixin flex-column-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@mixin flex-column-center-x {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@mixin flex-column-center-y {
    display: flex;
    flex-direction: column;
    align-items: center;
}

// OTHERS